import { Box, Grid } from '@material-ui/core'

import Head from '~/components/Head'
import { Project } from '~/types'
import ProjectPreview from '~/components/ProjectPreview'
import React from 'react'
import Tooltip from '~/components/Tooltip'
import { graphql } from 'gatsby'
import queryString from 'query-string'
import { sortByDate } from '~/utils/project'
import { useLocation } from '@reach/router'

export const query = graphql`
    {
        allSanityProject {
            nodes {
                title
                date
                categories {
                    url {
                        current
                    }
                }
                url {
                    current
                }
                teaserVideoFile {
                    asset {
                        url
                    }
                }
                teaserVideoPlaceholder {
                    asset {
                        url
                    }
                }
            }
        }
    }
`

type ViewProps = {
    data: {
        allSanityProject: {
            nodes: Project[]
        }
    }
}

const IndexPage = ({ data }: ViewProps) => {
    const { search: locationSearch } = useLocation()
    const projects = sortByDate((data || {}).allSanityProject.nodes)

    return (
        <>
            <Head title="Projects" />
            <Box height="100vh" overflow="scroll">
                <Grid container>
                    {projects
                        .filter(({ categories }) =>
                            categories.find((category) => {
                                const { s } = locationSearch
                                    ? queryString.parse(locationSearch)
                                    : { s: '' }
                                const selectedCategories = s ? (s as string).split(',') : []

                                return (
                                    !locationSearch ||
                                    selectedCategories.includes(category.url.current)
                                )
                            })
                        )
                        .map((project, index) => (
                            <Grid item xs={12} md={4} key={project.url.current}>
                                <ProjectPreview project={project} />
                            </Grid>
                        ))}
                </Grid>
            </Box>
            <Tooltip />
        </>
    )
}

export default IndexPage
